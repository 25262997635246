@tailwind base;
@tailwind components;
@tailwind utilities;



.menuLink  {
    @apply h-12 mt-5;
}

.menuLink .active #menuContainer{
    @apply text-yellow-600 font-bold ;
}
.menuLink .active #marker {
    @apply bg-yellow-600;
}

.menuLink .active #item {
    @apply bg-yellow-100 rounded-lg shadow-lg;
}

.menuLink #menuContainer #item:hover {
    @apply  bg-yellow-100 rounded-lg text-yellow-600 font-bold shadow-md;
}
